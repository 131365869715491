<template>
  <div class="account-change-password">
    <form @submit.prevent="submit">
      <form-group
        id="password"
        v-model="form.password"
        :errors="errors.password"
        type="password"
        placeholder="Digite nova senha"
        label="Nova senha"
      />

      <form-group
        id="confirm_password"
        v-model="form.confirm_password"
        type="password"
        placeholder="Digite nova senha"
        label="Confirmar senha"
      />

      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-primary px-4" :disabled="loading">
          <loading :show="loading">Salvar</loading>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import FIREBASE_ERROR from "@/constants/firebaseError.js";
import { formFields } from "@/functions/helpers.js";

export default {
  data() {
    return {
      loading: false,
      ...formFields(["password", "confirm_password"])
    };
  },
  methods: {
    submit() {
      if (this.form.confirm_password != this.form.password) {
        this.$message.error("Nova senha e senha de confirmação diferentes");
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("user/updatePassword", this.form.password)
        .then(() => {
          this.$message.success("Senha alterada com sucesso");
          const { form, errors } = formFields(["password", "confirm_password"]);
          this.form = { ...form };
          this.errors = { ...errors };
        })
        .catch(error => {
          this.$message.error(FIREBASE_ERROR[error.code]);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
