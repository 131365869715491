<template>
  <div class="view-account container-fluid pt-5">
    <div class="row">
      <div class="col-lg-6 mb-4 mb-lg-0">
        <div class="card-account">
          <h4 class="mb-4">Minha conta</h4>
          <form @submit.prevent="submit">
            <form-group
              id="email"
              v-model="form.email"
              :errors="errors.email"
              type="email"
              placeholder="Seu e-mail"
              readonly
              label="E-mail"
            />

            <form-group
              id="name"
              v-model="form.name"
              :errors="errors.name"
              type="text"
              placeholder="Seu nome"
              label="Nome"
            />

            <form-group
              id="phone"
              v-model="form.phone"
              :errors="errors.phone"
              type="text"
              placeholder="Número de celular"
              label="Telefone"
            />

            <div class="d-flex justify-content-end">
              <button
                type="submit"
                class="btn btn-primary px-4"
                :disabled="loading"
              >
                <loading :show="loading">Salvar</loading>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card-account">
          <h4 class="mb-4">Alterar senha</h4>
          <change-password />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formFields } from "@/functions/helpers.js";
import ChangePassword from "@/components/account/ChangePassword";

export default {
  components: { ChangePassword },
  data() {
    return {
      loading: false,
      ...formFields(["name", "phone", "email"], {
        ...this.$user.user
      })
    };
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("user/update", this.form)
        .then(() => {
          this.$message.success("Salvo com sucesso");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.view-account {
  h4 {
    font-weight: 500;
    font-size: 18px;
  }

  .card-account {
    border-radius: 8px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 16px 16px 24px 16px;
  }
}
</style>
